import React from 'react'
import Layout from '../../components/layout'
import TermsGrid from '../../components/terms/terms-grid'
import SEO from '../../components/seo'
import DomainRegistrationContent from '../../components/terms/domain-registration'

const DomainRegistration = ({ location }) => {
  return (
    <Layout>
      <SEO
        title='Domain Registration Agreement'
        pageType='home'
        flowType='none'
      />
      {typeof window !== 'undefined' && (
        <TermsGrid pathname={location.pathname} />
      )}
      <DomainRegistrationContent />
    </Layout>
  )
}

export default DomainRegistration
