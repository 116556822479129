import styled from 'styled-components'
import { theme, media } from '../../../helpers/theme'
import Link from 'gatsby-link'

const DomainRegistration = styled.div`
  margin: 0 auto;
  padding: ${theme.space[5]}px;
  display: flex;
  flex-direction: column;
  color: ${theme.colors.gray};

  ${media(768)} {
    width: 750px;
  }

  ${media(992)} {
    width: 970px;
  }
  ${media(1200)} {
    width: 1170px;
  }
`

DomainRegistration.title = styled.span`
  font-size: ${theme.font['3xl']};
  color: ${theme.colors.blackhighlight};
`

DomainRegistration.titleDash = styled.span`
  font-size: ${theme.font['3xl']};
  border-top: 1px solid ${theme.colors.lightGray};
  margin: ${theme.space[12]}px 0;
  color: ${theme.colors.blackhighlight};
`

DomainRegistration.subtitle = styled.span`
  padding-bottom: ${theme.space[4]}px;
  padding-top: ${theme.space[8]}px;
  font-size: ${theme.font['xl']};
  color: ${theme.colors.darkgray};
`

DomainRegistration.listSubtitle = styled.span`
  padding: ${theme.space[2]}px 0;
  font-size: ${theme.font['xl']};
  font-weight: bolder;
`
DomainRegistration.subtitleLink = styled(Link)`
  transition: all 0.2s ease-in-out;
  color: ${theme.colors.font.green};
  font-size: ${theme.font['lg']};
  font-weight: 400;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: ${theme.colors.font.darkGreen};
  }
`

DomainRegistration.list = styled.ol`
  padding: ${theme.space[2]}px 0;
  padding-left: ${theme.space[8]}px;
  color: ${theme.colors.font.lightGray};
  list-style-type: ${(props) =>
    props.inner
      ? 'lower-roman'
      : props.in
      ? 'decimal-leading-zero'
      : 'upper-alpha'};
`

DomainRegistration.listItem = styled.li`
  padding: ${theme.space[1]}px 0;
`

DomainRegistration.paragraph = styled.span`
  font-size: ${theme.font.base};
  color: ${theme.colors.font.lightGray};
  padding: ${theme.space[5]}px 0;
`

DomainRegistration.text = styled.span`
  font-size: ${theme.font.base};
  color: ${theme.colors.font.lightGray};
  padding: ${theme.space[2]}px 0;
`

DomainRegistration.paragraphItalic = styled.span`
  font-size: ${theme.font.base};
  font-style: italic;
`

DomainRegistration.link = styled(Link)`
  color: ${theme.colors.blue};
  font-size: ${theme.font.base};
  text-decoration: none;
  display: contents;
  font-style: inherit;
  &:hover {
    cursor: pointer;
    color: ${theme.colors.font.tealhighlight};
  }
`

DomainRegistration.linkContainer = styled.div`
  padding-top: ${theme.space[2]}px;
  display: flex;
  flex-direction: column;
`

DomainRegistration.inlineLink = styled(Link)`
  color: ${theme.colors.blue};
  text-decoration: none;
  font-size: inherit;
  font-style: inherit;
  &:hover {
    cursor: pointer;
    color: ${theme.colors.font.tealhighlight};
  }
`

DomainRegistration.listSingleItem = styled.div`
  padding: ${theme.space[2]}px 0;
`

DomainRegistration.boldSentence = styled.span`
  font-weight: 600;
`

DomainRegistration.updatedText = styled.span`
  margin-top: ${theme.space[6]}px;
  font-size: ${theme.font['sm']};
`

export default DomainRegistration
